import './App.css';
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

class App extends Component {
  
  constructor(props) {
    super(props);
    this.state = {sku: '', keyword: '', processing: false};

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  // componentDidMount() {
  //   fetch('https://api.apify.com/v2/acts/5kJ5lTZzvVYU5jEkA/run-sync-get-dataset-items?token=gH3RoYCdQKEs3rhbhsMu7qDBD',
  //   {headers: {
  //     'Content-Type': 'application/json'
  //   },
  //   method: 'POST',
  //   // body: JSON.stringify(data)
  // }).then(res => res.json()
  // .then(json => {
  //   this.setState({
  //     isLoaded: true,
  //     items: json
  //   })
  // }))
  // }
  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    })
  }

  handleSubmit(event) {
    console.log('starting analysis');
    this.setState({processing: true});

    const url = 'https://us-central1-daraz-scout.cloudfunctions.net/daraz-rank';
    
    fetch(url,
      {
        method: 'POST',
        body: JSON.stringify(this.state)
      }).then(res => res.json())
      .then(json => {
        console.log(json);
        if(json.body[0].message.code === 200) {
          const message= json.body[0].message;
          let element = <p>your product is on page {message.page} for "{this.state.keyword}"! </p>;
          ReactDOM.render(element, document.getElementById('result'));
        } else {
          let element = <p>could not find product, if you think this is not right please send SKU and keyword to me@hamzaafridi.com! </p>;
          ReactDOM.render(element, document.getElementById('result'));
        }
        this.setState({processing: false});
      });
    event.preventDefault();
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <h2>Daraz Rank Checker</h2>
          <h3>Check Your Poduct Rank - Search Result Analyze</h3>
          <h4>Get Page Rank Now</h4>
          <form onSubmit={this.handleSubmit} noValidate autoComplete="on" className="data-form">
            <TextField id="outlined-basic" variant="outlined" label="SKU" name = "sku" value = {this.state.sku} onChange={this.handleChange} style={{ marginRight: '3rem' }}/>
            <TextField id="outlined-basic" variant="outlined" label="Keyword" name = "keyword" value = {this.state.keyword} onChange={this.handleChange}/>
            <br></br>
            <Button variant="contained" color="primary" type="submit" value="submit" size="large" style={{margin: '20px'}} disabled={this.state.processing}>
              Submit
            </Button>
          </form>
        <LinearProgress style={{width: '60%', display: this.state.processing? 'inline': 'none'}} />
        <div id='result'></div>
        </header>
      </div>
    );
  }
}

export default App;
